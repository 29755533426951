import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UpdateRequestBodyInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if ( request.method === 'GET' ) return next.handle(request);

    const getBody: any = request.body || {};

    let body = {
      ...getBody,
      idTransaccion: this.getIdTransaccion(),
      payload: {
        ...getBody?.payload,
        idTransaccion: this.getIdTransaccion(),
        agenteId: sessionStorage.getItem('idEmpleado')
      }
    }

    if ( this.isPayloadStringType(request) ) {
      body = {
        ...getBody,
        idTransaccion: this.getIdTransaccion(),
        payload: ''
      }
    }

    const modifiedRequest = request.clone({
      body
    })
    return next.handle(modifiedRequest);
  }

  private getIdTransaccion(): string {
    return sessionStorage.getItem('idTransaccion') || '';
  }

  private isPayloadStringType(request: HttpRequest<unknown>): boolean {
    const filteredRequest = [
      'getPreGuardado',
      'getEnvioDomicilio',
      'getSolicitud',
      'getLogLambda',
      'refreshToken',
      'terminaFlujo',
    ];

    const urlRequest = request.url;

    for( let i = 0; i < filteredRequest.length; i++ ) {
      if ( urlRequest.includes(filteredRequest[i]) ) {
        return true;
      }
    }

    return false;
  }
}
